import type { SessionData } from '#auth'

export default function useUser() {
  function getFirstName(user: SessionData) {
    return user.name.split(' ')[0]
  }

  const getAddresses = () => {
    return useAsyncData(
      'addresses',
      () => useNuxtApp().$api.user.getAddresses(),
      {
        dedupe: 'defer',
      },
    )
  }

  const getDeliveryAddress = async (id: number) => {
    const addresses = await getAddresses()
    return addresses.data.value!.delivery.find((address) => address.id === id)!
  }

  return {
    getFirstName,
    getAddresses,
    getDeliveryAddress,
  }
}
